import { datadogLogs } from "@datadog/browser-logs";
import * as pako from "pako";
import { matchPath } from "react-router";
import { Controller } from "./Controller";
import { DashboardController } from "./DashboardController";
import * as Hawk from "hawk";

class CloudInventoryController extends Controller {
  history: any;
  location: any;
  _resourcesMap = {};
  constructor() {
    super("cloudInventory", { current: {}, dnsEntries: {}, publicIps: {} });
    this.registerRoute({ path: "/domains", strict: false, exact: true }, this.syncFootprint);
  }

  onROUTE_CHANGED(state, action) {
    matchPath(action.location.pathname, {
      path: "/cloud-inventory/:id",
      exact: true,
      strict: false
    });
    return { ...state };
  }

  afterSYNC_ORGANIZATION_SUCCESS() {
    this.setInitialized();
  }

  getAccountName(uid) {
    return Controller.get<DashboardController>("dashboard").getAccountName(uid);
  }

  generateResourceId(type, resource) {
    let infos = [type];
    if (type === "EC2Instances") {
      infos = [resource.InstanceId];
    } else if (type === "S3s" || type === "S3") {
      infos.push(resource.accountId);
      infos.push(resource.awsRegion);
      infos.push(resource.name);
    } else if (type === "SGs") {
      infos = [resource.GroupId];
    } else if (type === "EBS") {
      infos = [resource.VolumeId];
    } else if (type === "RDSs") {
      infos = [resource.DbiResourceId];
    } else if (type === "CloudFront") {
      infos = [resource.Id];
    } else if (type === "Route53") {
      infos.push(resource.accountId);
      infos.push(resource.Name);
    } else if (type === "Secrets") {
      infos.push(resource.accountId);
      infos.push(resource.awsRegion);
      infos.push(resource.Name);
    } else if (type === "WAF") {
      infos = [resource.WebACLArn];
    } else if (type === "ALB") {
      infos = [resource.arn];
    } else if (type === "Subnet") {
      infos = [resource.arn];
    }
    else {
      infos = [resource.arn];
    }
    return infos.join("_");
  }

  complianceChecks(type, resource) {
    let result: any = {};
    /*
      Compliance: {
        Encryption: {
          total: 0,
          exceptions: []
        },
        Tagging: {
          total: 0,
          exceptions: []
        },
        UnencryptedTraffic: {
          total: 0,
          exceptions: []
        }
      }
     */
    if (resource.Tags) {
      let found = { category: false, subcategory: false, environment: false };
      resource.Tags.forEach(tag => {
        if (
          tag.Key === "billing-category" &&
          ["internal", "support", "customers", "presales", "qa", "build", "ps", "dev", "support"].indexOf(tag.Value) >=
          0
        ) {
          found.category = true;
        }
        if (tag.Key === "billing-subcategory") {
          found.subcategory = true;
        }
        if (tag.Key === "environment") {
          found.environment = true;
        }
      });
      result.Tagging = found.category && found.environment && found.subcategory;
    }
    if (type === "CloudFront") {
      result.EncryptedTraffic = resource.DefaultCacheBehavior.ViewerProtocolPolicy !== "allow-all";
    } else if (type === "S3s") {
      result.Encryption = resource.encryption !== "";
    } else if (type === "SGs") {
      result.EncryptedTraffic = true;
      if (resource.Rules && resource.Rules.INGRESS) {
        resource.Rules.INGRESS.forEach(rule => {
          if (rule.Status === "REVIEWED") {
            return;
          }
          if (
            rule.ToPort === "any" ||
            rule.ToPort === 80 ||
            rule.ToPort === 21 ||
            rule.ToPort === 25 ||
            rule.ToPort === 20
          ) {
            result.EncryptedTraffic = false;
          }
        });
      }
    } else if (type === "EFS") {
      result.Encryption = resource.Encrypted;
    } else if (type === "RDSs") {
      result.Encryption = resource.StorageEncrypted;
    } else if (type === "EBS") {
      result.Encryption = resource.Encrypted;
    } else if (type === "EC2Instances") {
    }
    return result;
  }

  completeResource(type, resource) {
    resource._uuid = this.generateResourceId(type, resource);
    resource._type = type;
    this.addToMapping(resource);
    resource.compliant = this.complianceChecks(type, resource);
    return resource;
  }

  addToMapping(resource) {
    if (this._resourcesMap[resource._uuid]) {
      // console.warn("Resource doesn't have an unique ID", resource, this._resourcesMap[resource._uuid]);
    }
    this._resourcesMap[resource._uuid] = resource;
  }
  completeInventory(inventory, accountId) {
    let today = Date.now();
    let regions = Object.keys(inventory);

    if (regions.indexOf("global") >= 0) {
      inventory.global?.Route53?.zones?.forEach(zone => {
        zone.awsRegion = "global";
        zone.account = this.getAccountName(accountId);
        zone.accountId = accountId;
        zone = this.completeResource("Route53", zone);
      });
      inventory.global.CloudFront.distributions.forEach(distribution => {
        distribution.awsRegion = "global";
        distribution.account = this.getAccountName(accountId);
        distribution.accountId = accountId;
        distribution = this.completeResource("CloudFront", distribution);
      });
    }
    regions.forEach(selected => {
      ["S3s", "SGs", "WAF", "Secrets", "EFS", "RDSs", "EC2Instances", "EBS", "Others"].forEach(key => {
        if (!inventory[selected][key]) {
          return;
        }

        if (key === "WAF") {
          inventory[selected][key] = inventory[selected][key].WebACLs;
        }

        if (key === "Others") {
          let items = inventory[selected][key];
          inventory[selected]["WAF"] = items.filter(item => {
            let arn = item.arn || item.WebACLArn;
            return (arn.includes("arn:aws:waf") ||
              arn.includes("arn:aws:waf-regional") ||
              arn.includes("arn:aws:wafv2"))
          });

          inventory[selected]["WAF"].forEach(item => {
            item.WebACLArn = item.arn || item.WebACLArn;
            item.awsRegion = selected;
            item.account = this.getAccountName(accountId);
            item.accountId = accountId;
            item = this.completeResource(key, item);
          });

          inventory[selected][key] = items.filter(item => {
            let arn = item.arn || item.WebACLArn;
            return !(arn.includes("arn:aws:waf") ||
              arn.includes("arn:aws:waf-regional") ||
              arn.includes("arn:aws:wafv2"))
          });
        }

        if (key === "Others") {
          let items = inventory[selected][key];
          inventory[selected]["ALB"] = items.filter(item => {
            let arn = item.arn;
            return (arn.includes("arn:aws:elasticloadbalancing"))
          });

          inventory[selected]["ALB"].forEach(item => {
            item.WebACLArn = item.arn;
            item.awsRegion = selected;
            item.account = this.getAccountName(accountId);
            item.accountId = accountId;
            item = this.completeResource(key, item);
          });

          inventory[selected][key] = items.filter(item => {
            let arn = item.arn || item.WebACLArn;
            return !(arn.includes("arn:aws:elasticloadbalancing"))
          }); 
        }
        if (key === "Others") {
          let items = inventory[selected][key];
          inventory[selected]["Subnet"] = items.filter(item => {
            let arn = item.arn;
            return (arn.includes("arn:aws:ec2") & arn.includes("subnet"))
          });

          inventory[selected]["Subnet"].forEach(item => {
            item.WebACLArn = item.arn;
            item.awsRegion = selected;
            item.account = this.getAccountName(accountId);
            item.accountId = accountId;
            item = this.completeResource(key, item);
          });

          inventory[selected][key] = items.filter(item => {
            let arn = item.arn || item.WebACLArn;
            return !(arn.includes("arn:aws:ec2") & arn.includes("subnet"))
          });  
        }

        inventory[selected][key].forEach(item => {
          item.awsRegion = selected;
          item.account = this.getAccountName(accountId);
          item.accountId = accountId;
          item = this.completeResource(key, item);
          if (key === "SGs") {
            item.validatedRulesNb = 0;
            if (item.Rules) {
              item.rulesNb = item.Rules.EGRESS.length + item.Rules.INGRESS.length;

              item.Rules.EGRESS.forEach(rule => {
                if (rule.Status === "REVIEWED") {
                  item.validatedRulesNb++;
                }
              });
              item.Rules.INGRESS.forEach(rule => {
                if (rule.Status === "REVIEWED") {
                  item.validatedRulesNb++;
                }
              });
            }
          } else if (key === "EC2Instances") {
            let launchtime = new Date(item.LaunchTime).getTime();
            item.upTime = Math.floor((today - launchtime) / 86400000);
          }
        });
      });
    });
    return inventory;
  }

  getResource(uuid) {
    return this._resourcesMap[uuid];
  }

  async syncFootprint({ date }) {
    this.asyncAction("SYNC_FOOTPRINT", async (dispatch, getState) => {
      await this.waitInit();
      await Controller.get<DashboardController>("dashboard").waitInit();
      if (!date) {
        date = Controller.get<DashboardController>("dashboard")
          .getUIConfiguration()
          .cartography.split("/")
          .pop()
          .replace(/\.json\.gz$/, "");
      }
      const target = `/footprints/${date}`;
      let info = await this.ajax(target);
      let res = info.Location.match(/.*(\d{4})(\d{2})(\d{2})-(\d{2})\d{4}.json.gz.*/);
      if (res) {
        date = `${res[1]}-${res[2]}-${res[3]} ${res[4]}:00 UTC`;
      }
      let cacheId = `footprints-${info.Location.split("?")[0]}`;
      let cache = sessionStorage.getItem(cacheId);
      if (cache) {
        return JSON.parse(pako.inflate(cache, { to: "string" }));
      }
      let resp = await fetch(info.Location, { method: "GET" });
      const footprint = JSON.parse(pako.ungzip(await resp.arrayBuffer(), { to: "string" }));
      sessionStorage.setItem(cacheId, pako.deflate(JSON.stringify(footprint), { to: "string" }));
      return footprint;
    });
  }

  async loadInventories(force = false, selectedDate?: string) {
    if (Object.keys(this._resourcesMap).length > 0 && !force) {
      return;
    }

    this.asyncAction("SYNC_INVENTORY", async (dispatch, getState) => {
      await this.waitInit();
      let result = {};
      let orga = Controller.get<DashboardController>("dashboard").getOrganization() || {};
      let loads = [];
      // TODO Should dig into this but might be false positive
      /*eslint no-loop-func: "off"*/
      let date = "";
      let target = "";
      let error = "";
      const hawkCreds = {
        id: "dashboard",
        key: Controller._csrfToken,
        algorithm: "sha256"
      };

      Object.keys(orga).forEach(i => {
        if (selectedDate) {
          this._resourcesMap = {};
          target = `/aws/inventory/${i}/${selectedDate}`;
        } else {
          target = `/aws/inventory/${i}`;
        }

        const { header } = Hawk.client.header(Controller.endpoint + target, "GET", {
          credentials: hawkCreds
        });
        const Authorization = header;
        loads.push(
          fetch(Controller.endpoint + target, {
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Authorization
            },
            credentials: "include"
          })
            .then(async resp => {
              let info = await resp.json();
              if (info.Location) {
                let res = info.Location.match(/.*(\d{4})(\d{2})(\d{2})-(\d{2})\d{4}.json.gz.*/);
                if (res) {
                  date = `${res[1]}/${res[2]}/${res[3]} ${res[4]}:00 UTC`;// SEC-2017 as yyyy-mm-dd is not supported in safari
                }
                let cacheId = `awsInventory-${info.Location.split("?")[0]}`;
                let cache = sessionStorage.getItem(cacheId);
                if (cache) {
                  let data = JSON.parse(pako.inflate(cache, { to: "string" }));
                  result[i] = this.completeInventory(data, i);
                } else {
                  resp = await fetch(info.Location, { method: "GET" });
                  sessionStorage.clear();
                  let regionInventory = JSON.parse(pako.ungzip(await resp.arrayBuffer(), { to: "string" }));
                  //sessionStorage.setItem(cacheId, pako.deflate(JSON.stringify(regionInventory), { to: "string" }));
                  result[i] = this.completeInventory(regionInventory, i);
                }
              }
            })
            .catch(err => {
              datadogLogs.logger.error("Cannot load AWS inventory " + i);
              error = err;
            })
        );
      });
      await Promise.allSettled(loads);
      return { inventory: result, inventoryDate: date, error: error };
    });
  }

  setCurrentRessource(resource) {
    Controller.dispatch({ type: "SET_CURRENT_RESOURCE", resource });
  }

  onSET_FILTER(state, action) {
    let info = {};
    info[`selected${action.filterType}`] = action.filterValue;
    return {
      ...state,
      ...info
    };
  }

  setFilter(filterType, filterValue) {
    Controller.dispatch({ type: "SET_FILTER", filterType, filterValue });
  }

  showDomainDetailsDialog(target, data) {
    Controller.dispatch({ type: "SHOW_DOMAIN_DETAILS_DIALOG", target, data });
  }

  onSHOW_DOMAIN_DETAILS_DIALOG(state, target, data) {
    return { ...state, target, data };
  }

  hideDomainDetailsDialog() {
    Controller.dispatch({ type: "HIDE_DOMAIN_DETAILS_DIALOG" });
  }

  onHIDE_DOMAIN_DETAILS_DIALOG(state) {
    return { ...state, target: null, data: null };
  }
}
export { CloudInventoryController };
